jQuery(document).ready(function($) {
	$('.collapsible-header').on('click', function(event) {
		const eventTarget = this;
		const actualTarget = $(eventTarget)
		const panel = actualTarget.next(".collapsible-content")
		togglePanel(actualTarget,panel)
	});

	function slideToggle(el) {
		const ch = el.clientHeight !== undefined ? el.clientHeight : 0,
			sh = el.scrollHeight,
			isCollapsed = !ch,
			noHeightSet = !el.style.height;

		el.style.height = (isCollapsed || noHeightSet ? sh : 0) + "px";
		el.style.paddingBottom = (isCollapsed || noHeightSet ? 10 : 0) + "px";
	}

	function togglePanel(target,panel) {
		target.get(0).classList.toggle("open");
		panel.get(0).classList.toggle("open");
		slideToggle(panel.get(0));
	}

	$('.collapsible-header').each(function(index, element) {
		$(this).next(".collapsible-content").removeClass("open");
	});
});
